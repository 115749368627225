import { Link, useLoaderData, useLocation } from "@remix-run/react";
import cx from "classnames";
import { motion } from "framer-motion";
import lodash from "lodash";
import { ChevronDown, CircleX, Search as SearchIcon } from "lucide-react";
import { ChevronRight } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import Accordion from "~/components/Accordion";
import Collapse from "~/components/Collapse";
import Container from "~/components/Container";
import LogoBar from "~/components/LogoBar";
import Pagination from "~/components/Pagination";
import contents from "~/constants/contents";
import replaceWithDash from "~/utils/replaceWithDash";

const sSettings = {
  // Default parameters
  slidesPerView: 1.2,
  spaceBetween: 15,
  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.8,
      spaceBetween: 15,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2.2,
      spaceBetween: 15,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 1.2,
      spaceBetween: 15,
    },
    // when window width is >= 640px
    768: {
      slidesPerView: 1.2,
      spaceBetween: 15,
    },
  },
};
const ServiceRow = ({ service, i18n }) => {
  const onImgError = (e) => {
    e.target.onerror = null;
    e.target.src = `https://s3.hidsim.com/services/other.svg`;
  };

  return (
    <Link
      key={service._id}
      to={`/services/${service._id}/${replaceWithDash(service.name.common.toLowerCase())}`}
      className="flex justify-start bg-base-100 ps-4 align-middle gap-3"
      prefetch="viewport"
    >
      <div className="flex justify-start items-center">
        <img
          onError={onImgError}
          src={`https://s3.hidsim.com/services/${service.name.a2}.svg`}
          className="h-8 w-8 rounded-full border"
        />
      </div>

      <div className="flex justify-between items-center grow border-b border-slate-200/50 pe-6">
        <div className="flex flex-col justify-center align-middle font-semibold grow py-4">
          {service.name.common}
        </div>
        <div className="flex justify-center items-center">
          <ChevronRight
            size={18}
            className={cx({
              "rotate-180": i18n.dir() === "rtl",
            })}
          />
        </div>
      </div>
    </Link>
  );
};

function ServicesMobile({ textQuery, setTextQuery }) {
  const { services, inUrlQs, other, cookie } = useLoaderData<typeof loader>();
  const { t, i18n } = useTranslation("translation");
  const location = useLocation();

  const renderIntro = () => {
    return (
      <div className="bg-base-200">
        <Container className="grid grid-cols-1 gap-2 pt-5 px-5">
          <Collapse>
            <div className="flex flex-col gap-8">
              <h1 className="text-neutral-900 text-lg font-bold font-['Hubot-Sans','IRANYekanXVF']">
                {t("home.title")}
              </h1>
              <h2
                className="text-neutral-900 text-base font-normal font-['Figtree', 'IRANYekanXVF'] leading-7"
                dangerouslySetInnerHTML={{
                  __html: t("home.description"),
                }}
              />

              <section className="-mx-5">
                <Swiper {...sSettings} centeredSlides autoplay loop>
                  {contents.benefits.map((b) => {
                    if (
                      i18n.language !== "fa" &&
                      b.name === "home.benefits.visaAndCreditCardAccepted"
                    )
                      return null;
                    return (
                      <SwiperSlide key={b.name}>
                        <div className="h-40 p-5 bg-[#e0f7e8] rounded-lg flex-col justify-center items-center gap-5 flex">
                          <div className="p-3 bg-[#c2efd1] rounded-full justify-center items-center gap-2 flex">
                            <b.Icon />
                          </div>
                          <div className="text-center text-neutral-900 text-base font-semibold font-['Hubot-Sans', 'IRANYekanXVF'] leading-normal">
                            {t(b.name)}
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </section>

              <div className="flex flex-col gap-4">
                <h2 className="text-neutral-900 text-lg uppercase font-bold font-['Hubot-Sans', 'IRANYekanXVF']">
                  {t("faq")}
                </h2>

                <div className="flex items-start gap-6">
                  <div className="z-10 flex flex-col gap-3 w-full">
                    {contents?.faq.map((f) => (
                      <Accordion
                        key={f.q}
                        title={t(f.q)}
                        description={t(f.a)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </Container>
      </div>
    );
  };
  return (
    <section className="bg-base-200">
      <section
        style={{
          paddingBottom: "calc(env(safe-area-inset-bottom) + 5rem)",
        }}
        className="flex flex-col min-h-screen"
      >
        <div
          className="bg-base-100 border-b border-neutral-200"
          style={{
            paddingTop: "calc(env(safe-area-inset-top))",
          }}
        >
          <Container className="relative flex w-full flex-col gap-5 my-5">
            <div className="flex justify-between items-center px-3">
              <LogoBar />
              <div className="rounded-full bg-[#a8efc0] px-4 py-2 uppercase text-sm font-bold">
                {!cookie?.token && (
                  <Link to="/login" prefetch="viewport">
                    {t("login")}
                  </Link>
                )}
                {cookie?.token && (
                  <Link
                    className="flex gap-1 items-center"
                    to="/profile/?modal=charge"
                  >
                    <span>{cookie.user.balance}</span>
                    <span className="text-xs text-base-content/40 font-medium uppercase">
                      {t("coins")}
                    </span>
                  </Link>
                )}
              </div>
            </div>
            <div className="relative w-full rounded-full bg-base-200 focus:outline-none flex gap-1 items-center py-3">
              <SearchIcon className="text-black ms-4" strokeWidth={2} />
              <input
                type="text"
                placeholder={t("searchServices")}
                className="shrink-0 grow basis-0 items-center rounded-full bg-base-200 px-1 focus:outline-none w-full leading-none"
                onChange={(e) => setTextQuery(e.target.value)}
                value={textQuery}
              />
              {textQuery && (
                <button
                  onClick={() => setTextQuery("")}
                  className={cx(
                    "absolute top-3 flex h-6 w-6 items-center justify-center text-center text-3xl leading-none text-neutral-500",
                    {
                      "right-3": i18n.dir() === "ltr",
                      "left-3": i18n.dir() === "rtl",
                    },
                  )}
                >
                  <CircleX strokeWidth={1} />
                </button>
              )}
            </div>
          </Container>
        </div>

        {!cookie?.user && renderIntro()}
        <Container className="flex flex-col gap-2 py-5 px-5 grow">
          <div className="rounded-2xl overflow-hidden shrink">
            {!lodash.isEmpty(services) &&
              services?.results?.map((service, index) => (
                <ServiceRow
                  service={service}
                  key={service._id}
                  index={index}
                  i18n={i18n}
                />
              ))}
          </div>
          {lodash.isEmpty(services?.results) && (
            <Link
              to={`/services/${other._id}/${replaceWithDash(other.name.common.toLowerCase())}`}
              className="flex rounded-lg bg-base-200 p-3 align-middle"
            >
              <img
                src={`https://s3.hidsim.com/services/${other.name.a2}.svg`}
                className="h-8 w-8 rounded-full"
              />
              <div className="mx-2 block whitespace-nowrap overflow-ellipsis overflow-x-hidden max-w-48 md:max-w-52 xl:max-w-56">
                {other.name.common}
              </div>
            </Link>
          )}
        </Container>
        {!lodash.isEmpty(services?.results) && (
          <Pagination
            className="mb-4 shrink"
            limit={Number(inUrlQs.limit)}
            skip={Number(inUrlQs.skip)}
            total={Number(services.total)}
            resource="services"
            initialQs={inUrlQs}
            location={location}
          />
        )}
      </section>
    </section>
  );
}

export default ServicesMobile;
